import { FC, useContext, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { Empty, Spin } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/ru';

import { UserContext } from '@/context/user.context';
import useMobile from '@/hooks/use-mobile';
import { ChatMessage } from '@/types/chatItem';

import styles from './styles.module.scss';

type Props = {
	messages: ChatMessage[];
	connectedStatus: number;
	loading: boolean;
};

export const ChatMessageListBody: FC<Props> = ({ messages, connectedStatus, loading }) => {
	const { user } = useContext(UserContext);
	const isMobile = useMobile();
	const messageListRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (loading || connectedStatus !== WebSocket.OPEN || !messageListRef.current) return;

		messageListRef.current.scrollTo(0, messageListRef.current.scrollHeight);
	}, [loading, connectedStatus, messages]);

	if (loading || connectedStatus !== WebSocket.OPEN) {
		return (
			<div className={clsx(styles.spinnerWrapper, isMobile && styles.mobile)}>
				<Spin className={styles.spinner} size="large" spinning />
				<div className={styles.spinnerText}>Подключаемся...</div>
			</div>
		);
	}

	if (!messages.length)
		return (
			<Empty
				className={clsx(styles.empty, isMobile && styles.mobile)}
				description="Нет сообщений."
			/>
		);

	return (
		<div className={clsx(styles.list, isMobile && styles.mobile)} ref={messageListRef}>
			{messages.map((message) => {
				const isOwn = user.company_id === message.sender.id;
				return (
					<div
						className={clsx(styles.item, isOwn ? styles.right : styles.left)}
						key={message.id}
					>
						<div className={styles.text}>{message.text}</div>
						<div className={styles.footer}>
							<div className={styles.name}>{message.sender.companyName}</div>
							<div className={styles.date}>
								{moment(message.created).format('DD.MM HH:mm')}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
