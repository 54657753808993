import axios, { AxiosError } from 'axios';

import { Supplier } from '@/types/supplier';

/** Получение поклажедателя */
export default async function getSupplier(id?: number | string) {
	try {
		const { data } = await axios.get<Supplier>(`${process.env.HOST}/api_v1/depositor/${id}/`);
		return data;
	}
	catch (error) {
		if (error instanceof AxiosError) {
			console.log(error?.response?.data);
		}
		else {
			console.log(error);
		}
	}
}
