import { RequestService } from '@/api/services/request.service';
import { ChatItem, ChatMessageList } from '@/types/chatItem';

export const getChatMessages = async (chatId: string): Promise<ChatMessageList> => {
	const request = RequestService.getInstance();

	const { data } = await request.get<ChatItem>(`api_v1/chats/${chatId}`);

	return data.messages;
};
