export enum CompanyType {
	FishCompany = 'Depositor',
	Warehouse = 'stock',
}

export enum NotificationType {
	Success = 'success',
	Info = 'info',
	Warning = 'warning',
	Error = 'error',
}
