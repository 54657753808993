import { useRef, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';

import useOnClickOutside from '@/hooks/use-on-click-outside';

import { EnIcon, RuIcon } from './icons';

import './LangSelect.scss';

const flags = {
	ru: <RuIcon />,
	en: <EnIcon />,
};

export default function LangSelect() {
	const ref = useRef<HTMLDivElement>();
	const [open, setOpen] = useState(false);
	const router = useRouter();

	useOnClickOutside(ref, () => setOpen(false));

	// Обрабатывает смену языка, сохраняет выбранный вариант в localStorage
	const langChangeHandler = (newLang: 'ru' | 'en') => {
		if (router.locale != newLang) {
			setOpen(false);
			router.push(router.pathname, router.pathname, { locale: newLang });
		}
	};

	return (
		<div ref={ref} className={clsx('lang-select', open && 'open')}>
			<button
				className="lang-select-header"
				onClick={() => setOpen(!open)}
				aria-label="Сменить язык"
			>
				<div className="flag">{flags[router.locale]}</div>
				<svg
					className="lang-select-arrow"
					xmlns="http://www.w3.org/2000/svg"
					width="2.4rem"
					height="2.4rem"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						fill="#4587E8"
						d="M17 9.17a1 1 0 0 0-1.41 0L12 12.71 8.46 9.17a1 1 0 1 0-1.41 1.42l4.24 4.24a1.001 1.001 0 0 0 1.42 0L17 10.59a.999.999 0 0 0 0-1.42Z"
					/>
				</svg>
			</button>
			{open && (
				<ul className="lang-select-options">
					<li key="lang-ru">
						<Link
							href={router.pathname}
							locale="ru"
							onClick={() => langChangeHandler('ru')}
						>
							<div className="flag">
								<RuIcon />
							</div>
						</Link>
					</li>
					<li key="lang-en">
						<Link
							href={router.pathname}
							locale="en"
							onClick={() => langChangeHandler('en')}
						>
							<div className="flag">
								<EnIcon />
							</div>
						</Link>
					</li>
				</ul>
			)}
		</div>
	);
}
