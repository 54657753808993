import { RequestService } from '@/api/services/request.service';
import { PaginatedResponse } from '@/types/api/common';
import { ChatList } from '@/types/chatItem';

export const getChatList = async (): Promise<ChatList> => {
	const request = RequestService.getInstance();

	const { data } = await request.get<PaginatedResponse<ChatList>>('api_v1/chats');

	return data.results;
};
