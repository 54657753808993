import { useState } from 'react';

import { BindType } from '@/types/chatItem';
import { ChatContextType } from '@/types/context/chat.context';

export const useChatContext = (): ChatContextType => {
	const [bindId, setBindId] = useState<string | number>(null);
	const [bindType, setBindType] = useState<BindType>(null);

	const resetChatDefault = () => {
		setBindId(null);
		setBindType(null);
	};

	const updateBind = (bindId: string, bindType: BindType) => {
		setBindId(bindId);
		setBindType(bindType);
	};

	return {
		bindId,
		bindType,
		resetChatDefault,
		updateBind,
	};
};
