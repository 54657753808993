import { RequestService } from '@/api/services/request.service';
import { ChatItem } from '@/types/chatItem';

export const readChatMessages = async (chatId: string) => {
	const request = RequestService.getInstance();

	const { data: chat } = await request.patch<ChatItem>(`api_v1/chats/${chatId}/`);

	return chat;
};
