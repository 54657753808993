import { createContext } from 'react';

import { UserContextType } from '@/types/context/user.context';

export const UserContext = createContext<UserContextType>({
	user: null,
	balance: null,
	userCompany: null,
	initialDataLoading: false,
	updateUser: async () => null,
});
