import { RequestService } from '@/api/services/request.service';
import { User } from '@/types/user';

export type UserData = {
	id: number;
	first_name: string;
	last_name: string;
	surname: string;
	company_manager_name: string;
	email: string;
	company_id: string;
	role: 'depositor' | 'owner' | 'admin';
};

/**
 * Отдаёт информацию о пользователе
 */
export async function getUserInfo(): Promise<User> {
	const request = RequestService.getInstance();

	try {
		const { data } = await request.get<User>('/api_v1/me-user-info/');

		// Скрываем проданные или переданные товары
		return data;
	} catch (error) {
		console.error(error);
		throw error.response?.data ?? error.message ?? 'Ошибка при получении данных пользователя';
	}
}
