import { RequestService } from '@/api/services/request.service';
import { Balance } from '@/types/balance';

export const getBalance = async (): Promise<Balance> => {
	const request = RequestService.getInstance();
	try {
		const { data } = await request.get('/api_v1/balance/');

		return data?.[0];
	} catch (error) {
		console.error(error);
		throw error.response?.data ?? error.message ?? 'Ошибка при запросе баланса';
	}
};
