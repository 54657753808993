import { RequestService } from '@/api/services/request.service';
import { ChatItem } from '@/types/chatItem';

export const getChatByBindId = async (bindId: string | number): Promise<ChatItem> => {
	const request = RequestService.getInstance();

	const { data } = await request.get<ChatItem | null>(`api_v1/chats/${bindId}`);

	return data;
};
