import { RequestService } from '@/api/services/request.service';
import { DocData } from '@/types/legal';

export const getLegalDocsData = async (): Promise<DocData[]> => {
	const request = RequestService.getInstance();
	try {
		const { data } = await request.get('/api_v1/document');
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
};
