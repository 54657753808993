import { createContext } from 'react';

import { message, Modal, notification } from 'antd';

import { HookAPI } from 'antd/es/modal/useModal';

import { FeedbackContextType } from '@/types/context/feedback.context';

export const FeedbackContext = createContext<FeedbackContextType>({
	modal: Modal as unknown as HookAPI,
	notify: notification,
	message: message,
});
