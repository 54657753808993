import React, { useContext } from 'react';

import { useRouter } from 'next/router';

import { UserContext } from '@/context/user.context';
import useMobile from '@/hooks/use-mobile';
import { useTranslate } from '@/hooks/use-translate';

import { WarningIcon } from './icon';

import styles from './DemoHeader.module.scss';

export const DemoHeader = () => {
	const router = useRouter();
	const isMobile = useMobile();
	const t = useTranslate(en);
	const { user } = useContext(UserContext);

	const handleBannerClick = () => {
		user ? router.push('/') : router.push('/login');
	};

	return (
		<div className={styles.container} onClick={handleBannerClick}>
			<span className={styles.icon}>
				<WarningIcon />
			</span>
			<div>
				{isMobile
					? t('Демонстрационный доступ. Подробнее...')
					: t(
						'Это демонстрационная версия сайта. Зарегистрируйте свою или используйте нашу учетную запись для тестирования Личного Кабинета.',
					)}
			</div>
		</div>
	);
};

const en = {
	'Демонстрационный доступ. Подробнее...': 'Demo access. More...',
	'Это демонстрационная версия сайта. Зарегистрируйте свою или используйте нашу учетную запись для тестирования Личного Кабинета.':
		'This is a demo version of the site. Register your own or use our account to test the Personal Account.',
};
