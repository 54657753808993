import { RequestService } from '@/api/services/request.service';
import { DEFAULT_META_DATA } from '@/constants/common';
import { Metadata } from '@/types/metadata';

export const getMetadata = async (pageUrl: string = ''): Promise<Metadata> => {
	const request = RequestService.getInstance();
	const newMetaData = { ...DEFAULT_META_DATA };
	try {
		const { data } = await request.get<Metadata>('/api_v1/page-metadata/', {
			params: { url: pageUrl },
		});

		Object.keys(data).forEach((key) => {
			if (data[key] !== '') {
				newMetaData[key] = data[key];
			}
		});

		return newMetaData;
	} catch (err) {
		if (err?.response?.status !== 404) console.log(err);
		return newMetaData;
	}
};
