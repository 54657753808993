import { RequestService } from '@/api/services/request.service';
import { BindType, ChatItem } from '@/types/chatItem';

export const createChat = async (
	bindId: string | number,
	bindType: BindType,
): Promise<ChatItem> => {
	const request = RequestService.getInstance();

	try {
		const { data } = await request.post<ChatItem>('api_v1/chats/', { bindId, bindType });

		return data;
	} catch (error) {
		throw error.response?.data ?? error.message ?? error.statusText;
	}
};
