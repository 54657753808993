import { NextRouter } from 'next/router';

import axios, { AxiosError, AxiosInstance, CreateAxiosDefaults, HttpStatusCode } from 'axios';
import cookies from 'js-cookie';

import {
	HAS_WAREHOUSES_COOKIE_NAME,
	TOKEN_COOKIE_NAME,
	USER_LOGGED_KEY_NAME,
} from '@/auth/constants';
import { LocalStorage } from '@/utils/local-storage';

type RequestServiceConfig = {
	axiosConfig?: CreateAxiosDefaults;
	isServerSide?: boolean;
};

const DefaultRequestConfig: RequestServiceConfig = {
	axiosConfig: {},
	isServerSide: false,
} as const;

export class RequestService {
	private static instance: AxiosInstance;
	private static isServerSide: boolean = false;
	private static router: NextRouter;

	public static getInstance(config: RequestServiceConfig = DefaultRequestConfig) {
		if (!RequestService.instance) {
			RequestService.instance = axios.create({
				baseURL: process.env.HOST,
				...(config.axiosConfig ?? {}),
			});
		}
		return RequestService.instance;
	}

	public static setAuthHeader(value: string) {
		if (!RequestService.instance) {
			throw new Error('Axios instance has not been created');
		}
		RequestService.instance.defaults.headers['Authorization'] = `Bearer ${value}`;
	}

	public static addInterceptor() {
		RequestService.instance.interceptors.response.use(
			(response) => {
				return response;
			},
			(error: AxiosError) => {
				if (error?.response?.status === HttpStatusCode.Unauthorized) {
					cookies.remove(TOKEN_COOKIE_NAME);
					cookies.remove(HAS_WAREHOUSES_COOKIE_NAME);
					LocalStorage.removeItem(USER_LOGGED_KEY_NAME);

					if (!error.response.config.url.includes('token')) {
						if (RequestService.router) {
							void RequestService.router.push('login');
						} else {
							if (globalThis.location) {
								window.location.href = '/login';
							}
						}
					}
				}

				return Promise.reject(error);
			},
		);
	}
}
