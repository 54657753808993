import { FC, useContext } from 'react';

import { Badge, Empty } from 'antd';
import moment from 'moment';

import { UserContext } from '@/context/user.context';
import { ChatItem, ChatParticipant } from '@/types/chatItem';
import { CompanyType } from '@/types/common-entities';

import styles from './styles.module.scss';

type Props = {
	chats: ChatItem[];
	onChatSelect: (chatItem: ChatItem) => void;
};

export const ChatsList: FC<Props> = ({ chats, onChatSelect }) => {
	const { user } = useContext(UserContext);

	const getReceiverParticipant = (participants: ChatParticipant[]) => {
		return participants.find((pt) => pt.id !== user.company_id);
	};

	if (chats.length === 0) {
		return <Empty style={{ padding: '24px 0 0' }} description="Нет активных чатов." />;
	}

	return (
		<div className={styles.list}>
			{chats.map((chat) => {
				const receiver = getReceiverParticipant(chat.participants);
				const companyType =
					receiver.companyType === CompanyType.FishCompany ? 'Рыбная компания' : 'Склад';
				return (
					<div className={styles.item} key={chat.id} onClick={() => onChatSelect(chat)}>
						<div className={styles.title}>
							{chat.title}
							<Badge count={chat.unread} />
						</div>
						<div className={styles.subTitle}>{receiver.companyName}</div>
						<div className={styles.footer}>
							<div className={styles.companyType}>{companyType}</div>
							<div className={styles.date}>
								{moment(chat.messages[0].created).format('DD.MM.YYYY HH:mm')}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
