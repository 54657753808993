import cookies from 'js-cookie';

import { RequestService } from '@/api/services/request.service';
import {
	HAS_WAREHOUSES_COOKIE_NAME,
	TOKEN_COOKIE_NAME,
	USER_LOGGED_KEY_NAME,
} from '@/auth/constants';
import { LocalStorage } from '@/utils/local-storage';

export default async function logout() {
	const request = RequestService.getInstance();
	await request.delete('accounts/logout');
	cookies.remove(TOKEN_COOKIE_NAME);
	cookies.remove(HAS_WAREHOUSES_COOKIE_NAME);
	LocalStorage.removeItem(USER_LOGGED_KEY_NAME);
}
