import React, { FC, useEffect, useState } from 'react';

import cookies from 'js-cookie';

import { DocsLinks } from '@/types/legal';
import Button from '@/UI/Button';

import cls from './CookiesPopup.module.scss';

type Props = {
	docsLinks: DocsLinks;
};

export const CookiesPopup: FC<Props> = ({ docsLinks }) => {
	const [cookiesAccepted, setCookiesAccepted] = useState(true);
	const { termOfCookie } = docsLinks;

	useEffect(() => {
		const cookiesItem = cookies.get('cookiesAccepted');
		setCookiesAccepted(cookiesItem === 'true');
	}, []);

	const acceptHandler = () => {
		cookies.set('cookiesAccepted', 'true');
		setCookiesAccepted(true);
	};

	if (cookiesAccepted) return;

	return (
		<div className={cls.container}>
			<div className={cls.card}>
				<p className={cls.text}>
					Продолжая просмотр этого сайта, Вы соглашаетесь на обработку файлов cookie в
					соответствии с Политикой использования FishPlace.ru файлов cookie.
				</p>
				<div className={cls.footer}>
					<Button className={cls.link} target="_blank" variant="pale" href={termOfCookie}>
						Узнать подробнее
					</Button>
					<Button onClick={acceptHandler} className={cls.button}>
						Продолжить
					</Button>
				</div>
			</div>
		</div>
	);
};
