import { HTMLProps } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';

import Spinner from '../Spinner';

import './Button.scss';

type ButtonProps = HTMLProps<HTMLButtonElement> & {
	variant?:
		| 'default'
		| 'text'
		| 'pale'
		| 'danger'
		| 'inverted'
		| 'form'
		| 'pale-with-border'
		| 'link';
	/** Если указан, кнопка становится ссылкой (тег `a`) */
	href?: string;
	disabled?: boolean;
	loading?: boolean;
};

const Button = (props: ButtonProps) => {
	const { disabled, loading } = props;
	const router = useRouter();
	const { locale } = router;
	return props.href ? (
		// @ts-ignore
		<Link
			disabled={disabled || loading}
			{...props}
			className={clsx('button', props.variant, props.className, loading && 'loading')}
			href={props.href}
			locale={locale}
		>
			{props.children}
			{loading && (
				<div className="loader">
					<Spinner size={20} color="#ffffff" />
				</div>
			)}
		</Link>
	) : (
		// @ts-ignore
		<button
			{...props}
			disabled={disabled || loading}
			className={clsx('button', props.variant, props.className, loading && 'loading')}
		>
			{props.children}
			{loading && (
				<div className="loader">
					<Spinner size={20} color="#ffffff" />
				</div>
			)}
		</button>
	);
};

export default Button;
