import React from 'react';

import { Metadata } from '@/types/metadata';

type Props = {
	metadata: Metadata;
	pageUrl: string;
};

export const HeadWithMetaDataGlobal = ({ metadata, pageUrl }: Props): React.ReactElement[] => {
	const { imageUrl } = metadata;

	const AdditionalTags = (): React.ReactElement[] => {
		if (!metadata.additionalTags?.length) return [];

		return metadata.additionalTags.map((tag) => {
			const key = [tag.name, ...Object.entries(tag.attributes).flat()].join('-');
			return React.createElement(tag.name, {
				...tag.attributes,
				key,
			});
		});
	};

	return [
		<title key="title">{metadata.title}</title>,
		<meta key={metadata.description} name="description" content={metadata.description} />,
		<meta key={metadata.keywords} name="keywords" content={metadata.keywords} />,
		<meta
			key="width=device-width, initial-scale=1"
			name="viewport"
			content="width=device-width, initial-scale=1"
		/>,
		<link key="/favicon.ico" rel="icon" href="/favicon.ico" />,
		<meta key={metadata.title} property="og:title" content={metadata.title} />,
		<meta key="website" property="og:type" content="website" />,
		<meta
			key={metadata.description}
			property="og:description"
			content={metadata.description}
		/>,
		<meta key={imageUrl} property="og:image" content={imageUrl} />,
		<meta
			key={`https://fishplace.ru${pageUrl}`}
			property="og:url"
			content={`https://fishplace.ru${pageUrl}`}
		/>,
		<meta key="fishplace.ru" property="og:site_name" content="fishplace.ru" />,
		<meta key="summary" name="twitter:card" content="summary" />,
		<meta key={metadata.title} itemProp="name" name="twitter:title" content={metadata.title} />,
		<meta
			key={metadata.description}
			name="twitter:description"
			content={metadata.description}
		/>,
		<meta key={imageUrl} name="twitter:image" content={imageUrl} />,
		<meta
			key={`https://fishplace.ru${pageUrl}`}
			name="twitter:url"
			content={`https://fishplace.ru${pageUrl}`}
		/>,
		...AdditionalTags(),
	];
};
