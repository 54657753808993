class LocalStorageInit extends EventTarget {
	setItem(key: string, value: string) {
		globalThis.localStorage.setItem(key, value);
		this.dispatchEvent(new CustomEvent('set', { detail: { key, value } }));
	}

	getItem(key: string) {
		return globalThis.localStorage?.getItem(key);
	}

	removeItem(key: string) {
		globalThis.localStorage?.removeItem(key);
		this.dispatchEvent(new CustomEvent('remove', { detail: { key } }));
	}

	hasItem(key: string) {
		return (
			globalThis.localStorage?.getItem(key) !== null &&
			globalThis.localStorage?.getItem(key) !== undefined
		);
	}
}

export const LocalStorage = new LocalStorageInit();
