import { SET_LANG } from '../actionTypes';

type LocalizationReducer = {
	language: 'ru' | 'en'
}

const initialState: LocalizationReducer = {
	language: 'ru'
};

export default function localizationReducer(state = initialState, action) {
	switch (action.type) {

		case SET_LANG:
			return { ...state, language: action.payload };

		default:
			return state;

	}
}
