import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

type Props = {
	size?: number;
	color?: string;
};
export default function Spinner({ size = 24, color = '#1677ff' }: Props) {
	return <Spin indicator={<LoadingOutlined style={{ fontSize: size, color: color }} spin />} />;
}
