import { useEffect, useState } from 'react';

export default function useMobile() {
	const [mobile, setMobile] = useState(false);
	useEffect(() => {
		function handler() {
			setMobile(window.screen.width <= 768);
		}
		handler();
		addEventListener('resize', handler);
		return () => {
			removeEventListener('resize', handler);
		};
	}, []);
	return mobile;
}
