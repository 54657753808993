import React, { FC } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import urlJoin from 'url-join';

import { Metadata } from '@/types/metadata';

type Props = {
	metadata: Metadata;
};

export const HeadWithMetaData: FC<Props> = ({ metadata }) => {
	const router = useRouter();
	const pageUrl = router.pathname;
	const { imageUrl } = metadata;

	const AdditionalTags = () => {
		if (!metadata.additionalTags?.length) return null;

		return metadata.additionalTags.map((tag) => {
			const key = [tag.name, ...Object.entries(tag.attributes).flat()].join('-');
			return React.createElement(tag.name, {
				...tag.attributes,
				key,
			});
		});
	};

	return (
		<Head>
			<title>{metadata.title}</title>
			<meta name="description" content={metadata.description} />
			<meta name="keywords" content={metadata.keywords} />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<link rel="icon" href="/favicon.ico" />
			<meta property="og:title" content={metadata.title} />
			<meta property="og:type" content="website" />
			<meta property="og:description" content={metadata.description} />
			<meta property="og:image" content={imageUrl} />
			<meta property="og:url" content={urlJoin('https://fishplace.ru', pageUrl)} />
			<meta property="og:site_name" content="fishplace.ru" />
			<meta name="twitter:card" content="summary" />
			<meta itemProp="name" name="twitter:title" content={metadata.title} />
			<meta name="twitter:description" content={metadata.description} />
			<meta name="twitter:image" content={imageUrl} />
			<meta name="twitter:url" content={`https://fishplace.ru${pageUrl}`} />
			{AdditionalTags()}
		</Head>
	);
};
