import { useState } from 'react';

import { getLegalDocsData } from '@/api/get-legal-docs-data';
import { DOC_LINKS_KEY_NAME } from '@/auth/constants';
import useAsyncEffect from '@/hooks/use-async-effect';
import { DocsLinks, DocumentName } from '@/types/legal';
import { LocalStorage } from '@/utils/local-storage';

export const useDocLinks = () => {
	const [docsLinks, setDocsLinks] = useState<DocsLinks>();

	useAsyncEffect(async () => {
		if (LocalStorage.hasItem(DOC_LINKS_KEY_NAME)) {
			setDocsLinks(JSON.parse(LocalStorage.getItem(DOC_LINKS_KEY_NAME)));
		} else {
			console.log('no docsLinks in local storage. loading from server');
			const docsData = await getLegalDocsData();
			const docsLinkList = docsData.reduce((acc, doc) => {
				acc[DocumentName[doc.title_en]] = doc.file ?? '';
				return acc;
			}, {} as DocsLinks);
			setDocsLinks(docsLinkList);
			LocalStorage.setItem(DOC_LINKS_KEY_NAME, JSON.stringify(docsLinkList));
		}
	}, []);

	return docsLinks;
};
