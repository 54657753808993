import { ValuesAsKeys } from '@/types/common';

export type DocsLinks = ValuesAsKeys<typeof DocumentName, string>;

export enum ServerDocumentName {
	TermOfUse = 'term_of_use',
	UserAgreement = 'user_agreement',
	PrivacyPolicy = 'privacy_policy',
	TermOfCookie = 'term_of_cookie',
}

export const DocumentName = {
	[ServerDocumentName.TermOfUse]: 'termOfUse',
	[ServerDocumentName.UserAgreement]: 'userAgreement',
	[ServerDocumentName.PrivacyPolicy]: 'privacyPolicy',
	[ServerDocumentName.TermOfCookie]: 'termOfCookie',
} as const;

export type RuDocNames =
	| 'Лицензионный договор'
	| 'Пользовательское соглашение'
	| 'Политика обработки персональных данных'
	| 'Соглашение на использование cookie';

export type DocData = {
	id: number;
	updated: Date | string;
	title: RuDocNames;
	title_en: ServerDocumentName;
	file: string;
};
