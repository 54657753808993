import React, { FC, useEffect, useState } from 'react';

import { FloatButton } from 'antd';
import axios from 'axios';

import { CloudServerOutlined, InfoCircleOutlined } from '@ant-design/icons';

export const AppInfo: FC = () => {
	const [backInfo, setBackInfo] = useState<Record<string, string> | null>(null);
	const isLocal = globalThis.location?.href?.includes?.('localhost');

	const getAppInfo = async () => {
		try {
			const { data } = await axios.get(`${process.env.HOST}/api_v1/app-data/`);
			setBackInfo(data);
		} catch {}
	};
	useEffect(() => {
		void getAppInfo();
	}, []);

	return (
		<>
			<FloatButton.Group
				trigger="hover"
				type="primary"
				style={{ left: 48, bottom: 12 }}
				icon={<InfoCircleOutlined />}
			>
				{!!backInfo && (
					<FloatButton
						icon={<CloudServerOutlined />}
						tooltip={
							<div>
								<p>Server</p>
								<p>Env: {backInfo.environment}</p>
								<p>
									<a
										target="_blank"
										href={backInfo.merge_request_url}
										rel="noreferrer"
									>
										{backInfo.merge_request_title}
									</a>
								</p>
							</div>
						}
					/>
				)}
				{!isLocal && (
					<FloatButton
						icon={<InfoCircleOutlined />}
						tooltip={
							<div>
								<p>Client</p>
								<p>Env: {process.env.ENVIRONMENT}</p>
								<a
									target="_blank"
									href={process.env.MERGE_REQUEST_URL}
									rel="noreferrer"
								>
									{process.env.MERGE_REQUEST_TITLE}
								</a>
							</div>
						}
					/>
				)}
			</FloatButton.Group>
		</>
	);
};
