export const RuIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg"
				 width="30"
				 height="20"
				 fill="none"
				 viewBox="0 0 30 20"
	>
		<g clipPath="url(#a)">
			<path fill="#fff" d="M30 0H0v10h30V0Z" />
			<path fill="#FF0606" d="M30 10H0v10h30V10Z" />
			<path fill="#14489D" d="M30 6.667H0v6.666h30V6.666Z" />
		</g>
		<defs>
			<clipPath id="a">
				<rect width="30" height="20" fill="#fff" rx="4" />
			</clipPath>
		</defs>
	</svg>
);

export const EnIcon = () => (
	<svg
		width="30" height="21"
		viewBox="0 0 30 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_5348_45634)">
			<g clipPath="url(#clip1_5348_45634)">
				<mask id="mask0_5348_45634" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="-5" y="1" width="39"
					height="20"
				>
					<path d="M-5 1V20.5H34V1H-5Z" fill="white" />
				</mask>
				<g mask="url(#mask0_5348_45634)">
					<path d="M-5 1V20.5H34V1H-5Z" fill="#012169" />
					<path d="M-5 1L34 20.5L-5 1ZM34 1L-5 20.5L34 1Z" fill="black" />
					<path d="M-5 1L34 20.5M34 1L-5 20.5" stroke="white" strokeWidth="3.9" />
					<mask id="mask1_5348_45634" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="-5" y="1" width="39"
						height="20"
					>
						<path
							d="M14.5 10.75H34V20.5L14.5 10.75ZM14.5 10.75V20.5H-5L14.5 10.75ZM14.5 10.75H-5V1L14.5 10.75ZM14.5 10.75V1H34L14.5 10.75Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask1_5348_45634)">
						<path d="M-5 1L34 20.5L-5 1ZM34 1L-5 20.5L34 1Z" fill="black" />
						<path d="M-5 1L34 20.5M34 1L-5 20.5" stroke="#C8102E" strokeWidth="2.6" />
					</g>
					<path d="M14.5 1V20.5V1ZM-5 10.75H34H-5Z" fill="black" />
					<path d="M14.5 1V20.5M-5 10.75H34" stroke="white" strokeWidth="6.5" />
					<path d="M14.5 1V20.5V1ZM-5 10.75H34H-5Z" fill="black" />
					<path d="M14.5 1V20.5M-5 10.75H34" stroke="#C8102E" strokeWidth="3.9" />
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_5348_45634">
				<rect y="0.5" width="30" height="20" rx="4" fill="white" />
			</clipPath>
			<clipPath id="clip1_5348_45634">
				<rect width="39" height="19.5" fill="white" transform="translate(-5 1)" />
			</clipPath>
		</defs>
	</svg>
);
