import EventEmitter from 'events';

import retry from 'async-retry';
import cookies from 'js-cookie';
import urlJoin from 'url-join';

import { TOKEN_COOKIE_NAME } from '@/auth/constants';
import {
	IncomingSocketMessage,
	PostChatMessage,
	SocketEvents,
	SocketMessageType,
} from '@/types/chatItem';

export class SocketService extends EventEmitter {
	private socket: WebSocket;
	private readonly url: string;

	constructor(path: string = '') {
		super();
		this.url =
			`${urlJoin(process.env.WEBSOCKET_HOST, 'ws', path)}?token=${cookies.get(TOKEN_COOKIE_NAME)}`.replace(
				'http',
				'ws',
			);
	}

	get connected() {
		return this.socket?.OPEN ?? false;
	}

	private _getSocketConnection = (): Promise<WebSocket> => {
		return new Promise((resolve, reject) => {
			const socket = new WebSocket(this.url);

			socket.onopen = (event) => {
				socket.onclose = (event) => this.emit(SocketEvents.Closed, event);

				socket.onmessage = (event) => {
					const payload: IncomingSocketMessage<any> = JSON.parse(event.data);

					const { type, data } = payload;
					if (type === SocketMessageType.Message) {
						this.emit(SocketEvents.Message, data);
					}

					if (type === SocketMessageType.NewMessageNotification) {
						this.emit(SocketEvents.NewMessageNotification, data);
					}
				};

				this.emit(SocketEvents.Connection, event);

				resolve(socket);
			};
			socket.onerror = reject;
		});
	};

	async connect() {
		this.socket = await retry<WebSocket>(() => this._getSocketConnection(), {
			retries: 5,
			maxTimeout: 10_000,
			onRetry: () => console.log('retry'),
		});
	}

	send<T>(data: PostChatMessage<T>) {
		if (!this.socket) throw new Error('Сокет не подключён');

		this.socket.send(JSON.stringify(data));
	}

	close() {
		this.socket?.close();
		this.socket = null;
	}
}
