import React, { FC, useEffect, useRef, useState } from 'react';

import { Button, Input, type InputRef } from 'antd';

import { SendOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

type Props = {
	chatId: string | null;
	onMessage: (text: string) => void;
	disabled: boolean;
};

export const TextInput: FC<Props> = ({ chatId, onMessage, disabled }) => {
	const delay = 500;
	const [delayTimerId, setDelayTimerId] = useState<number>(null);

	const inputRef = useRef<InputRef>(null);

	const [value, setValue] = useState('');

	const handleMessage = () => {
		if (delayTimerId !== null || !inputRef.current?.input.value?.length) return;

		onMessage(value);
		setValue('');
		setDelayTimerId(
			+setTimeout(() => {
				setDelayTimerId(null);
			}, delay),
		);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleMessage();
		}
	};

	useEffect(() => {
		if (!chatId) {
			setValue('');
		}
	}, [chatId]);

	useEffect(() => {
		inputRef.current!.focus();
	}, [inputRef.current]);

	useEffect(() => {
		return () => clearTimeout(delayTimerId);
	}, []);

	return (
		<div className={styles.wrapper}>
			<Input
				ref={inputRef}
				value={value}
				autoFocus={true}
				onChange={(e) => setValue(e.target.value)}
				className={styles.input}
				disabled={disabled}
				onKeyDown={handleKeyDown}
			/>
			<Button
				onClick={handleMessage}
				icon={<SendOutlined />}
				className={styles.submitButton}
				loading={delayTimerId !== null}
				disabled={!value.length || disabled || delayTimerId !== null}
			/>
		</div>
	);
};
