import { FC, MouseEventHandler } from 'react';

import { Space } from 'antd';
import clsx from 'clsx';

import useMobile from '@/hooks/use-mobile';
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

type Props = {
	chatOpen?: boolean;
	onClick: () => void;
	onBackClick: MouseEventHandler<HTMLSpanElement>;
	title: string;
	subtitle: string;
};

export const ChatHeader: FC<Props> = ({ chatOpen, onBackClick, onClick, subtitle, title }) => {
	const isMobile = useMobile();

	return (
		<div onClick={onClick} className={clsx(styles.header, isMobile && styles.mobile)}>
			{chatOpen && (
				<div className={styles.backButtonWrapper} onClick={onBackClick}>
					<div className={styles.backButton}>
						<ArrowLeftOutlined />
					</div>
				</div>
			)}
			<div className={clsx(styles.title, chatOpen && styles.chatOpen)}>
				<Space direction="vertical" size={4}>
					<p className={clsx(styles.titleText, chatOpen && styles.chatOpen)}>
						{chatOpen ? title : 'Сообщения'}
					</p>
					{chatOpen && subtitle && <p className={styles.subtitleText}>{subtitle}</p>}
				</Space>
			</div>
			<div className={styles.iconToggle}>
				<DownOutlined />
			</div>
		</div>
	);
};
