import { FC } from 'react';

import { Skeleton } from 'antd';

import { ChatsList } from '@/components/Chat/ChatList/ChatsList';
import { ChatMessageList } from '@/components/Chat/ChatMessageList/ChatMessageList';
import { ChatItem, ChatList } from '@/types/chatItem';

type Props = {
	loading: boolean;
	activeChatId: string | null;
	onChatSelect: (chatItem: ChatItem) => void;
	chats: ChatList;
};

export const ChatWindowBody: FC<Props> = ({ loading, chats, activeChatId, onChatSelect }) => {
	if (loading)
		return <Skeleton active style={{ padding: '16px' }} paragraph={{ rows: 2 }} avatar />;

	if (activeChatId) return <ChatMessageList chatId={activeChatId} />;

	return <ChatsList chats={chats} onChatSelect={onChatSelect} />;
};
