export const ChatIcon = () => (
	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
		<title>icons / icons-24 / chat</title>
		<g
			id="icons-/--icons-24-/-chat"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<path
				d="M17,11 L7,11 C6.448,11 6,10.552 6,10 C6,9.448 6.448,9 7,9 L17,9 C17.553,9 18,9.448 18,10 C18,10.552 17.553,11 17,11 Z"
				id="Path"
				fill="#FBFBFB"
			></path>
			<path
				d="M7,15 L11,15 C11.553,15 12,14.553 12,14 C12,13.447 11.553,13 11,13 L7,13 C6.448,13 6,13.447 6,14 C6,14.553 6.448,15 7,15 Z"
				id="Path"
				fill="#FBFBFB"
			></path>
			<path
				d="M3,24 C2.871,24 2.741,23.98 2.617,23.92 C2.244,23.77 2,23.4 2,23 L2,7 C2,5.35 3.346,4 5,4 L19,4 C20.654,4 22,5.35 22,7 L22,17 C22,18.65 20.654,20 19,20 L7.414,20 L3.707,23.71 C3.516,23.9 3.26,24 3,24 Z M5,6 C4.449,6 4,6.45 4,7 L4,20.59 L6.293,18.29 C6.48,18.1 6.735,18 7,18 L19,18 C19.552,18 20,17.55 20,17 L20,7 C20,6.45 19.552,6 19,6 L5,6 Z"
				id="Shape"
				fill="#FBFBFB"
			></path>
		</g>
	</svg>
);
