import { RefObject, useEffect } from 'react';

type Handler = (event: MouseEvent) => void;

export default function useOnClickOutside<T extends HTMLElement>(
	ref: RefObject<T>,
	callback: Handler
) {
	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			// @ts-ignore
			const isScrollbarClick = e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight;
			if (!isScrollbarClick && ref.current && !ref.current.contains(e.target as Node)) {
				callback?.(e);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});
}
