import { useCallback } from 'react';

import { useRouter } from 'next/router';

/**
 * Возвращает колбэк для перевода
 * @param translation Объект с ключами
 * Пример: {
 * 	'Фильтры': 'Filters',
 * 	'Местоположение': 'Location'
 * };
 */
export function useTranslate(translation: Record<string, string>, defaultLang = 'ru') {
	const router = useRouter();

	return useCallback(
		(str: string, fallback?: string) => {
			if (router.locale === router.defaultLocale) return str;
			return translation[str] ?? fallback ?? str;
		},
		[router.defaultLocale, router.locale, translation],
	);
}
