import { createContext } from 'react';

import { BindType } from '@/types/chatItem';
import { ChatContextType } from '@/types/context/chat.context';

export const ChatContext = createContext<ChatContextType>({
	bindId: null,
	bindType: null,
	resetChatDefault: function () {},
	updateBind: function (bindId: string, bindType: string) {},
});
